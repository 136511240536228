var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticStyle:{"text-transform":"lowercase"}},[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.$t('navigation.tournament'))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('tournament.groups'))+" ")])])]},proxy:true}])}),_c('div',{staticClass:"d-flex w-100 mb-2",staticStyle:{"gap":"2rem"}},[_c('tournaments-select',{staticStyle:{"width":"200px"},on:{"input":_vm.selectTournament},model:{value:(_vm.tournament),callback:function ($$v) {_vm.tournament=$$v},expression:"tournament"}})],1),_c('div',{},[_vm._l((_vm.groupsViewItems),function(gTable,index){return _c('div',{key:index,staticClass:"mt-4"},[_c('div',{staticClass:"d-flex align-items-end justify-content-between mb-2"},[_c('h3',[_vm._v(_vm._s(gTable.group.name))]),_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.downloadTop100({ index: index, group: gTable.group })}}},[_c('span',[_vm._v(_vm._s(_vm.$t('download_top_100'))+" ("+_vm._s(gTable.group.name)+")")]),(gTable.downloading)?_c('b-spinner',{staticClass:"ml-1",attrs:{"label":"Spinning","small":""}}):_vm._e()],1)],1),_c('b-table',{staticClass:"news__table",attrs:{"busy":gTable.busy,"fields":_vm.fields,"items":gTable.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.id)+" ")]}},{key:"cell(group)",fn:function(){return [_vm._v(" "+_vm._s(gTable.group.name)+" ")]},proxy:true},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"200px"}},[_vm._v(" "+_vm._s(item.user.first_name)+" "+_vm._s(item.user.last_name)+" ")])]}},{key:"cell(position)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.position)+" ")]}},{key:"cell(score)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_scores)+" ")]}},{key:"cell(phone)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.phone)+" ")]}},{key:"cell(gift)",fn:function(ref){
var item = ref.item;
return [(item.gift && item.gift.product)?_c('span',[_vm._v(" "+_vm._s(_vm.checkLang(item.gift.product.name))+" ")]):_c('span')]}},{key:"cell(images)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-img"},[(
                                item.gift &&
                                item.gift.product &&
                                item.gift.product.images.length > 0
                            )?_c('img',{attrs:{"src":item.gift.product.images[0].path}}):_c('img',{attrs:{"src":require("../../../assets/images/icons/no-photo.png")}})])]}},{key:"cell(actions)",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.changeMerchant}},[_c('router-link',{attrs:{"to":{
                                    name: 'subscriber-task-progress',
                                    params: { id: item.user.id }
                                }}},[_c('feather-icon',{attrs:{"color":"#fff","icon":"TrendingUpIcon"}})],1)],1)],1)]}}],null,true)}),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[_c('b-pagination',{staticClass:"float-right mt-2 mb-2 mb-0",attrs:{"per-page":gTable.pagination.perPage,"total-rows":gTable.pagination.totalItem,"first-number":"","last-number":""},on:{"change":function($event){return _vm.changeGroupItemPage({
                                page: $event,
                                indexOfGroup: index,
                                groupId: gTable.group.id
                            })}},model:{value:(gTable.pagination.current),callback:function ($$v) {_vm.$set(gTable.pagination, "current", $$v)},expression:"gTable.pagination.current"}})],1)],1)],1)}),(_vm.isFetching)?_c('div',{staticClass:"d-flex justify-content-center mt-4"},[_c('b-spinner',{attrs:{"label":"Spinning","variant":"primary"}})],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }