<script>
import api from '@/services/api';
import 'flatpickr/dist/flatpickr.css';
import PageTitle from '@/components/PageTitle';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';

import { checkLang } from '@/util/helper';
import { checkLocales } from '@/util/lang';
import pasteImageFileAgent from '@/mixins/pasteImageFileAgent';
import { BCol, BPagination, BRow, BTable, VBTooltip } from 'bootstrap-vue';
// import GroupsSelect from '@/views/tournament/statistics/elements/GroupsSelect.vue';
import TournamentsSelect from '@/views/tournament/tournament-tasks/TournamentsSelect.vue';
import { utils, writeFileXLSX } from 'xlsx';
import { addZero, dateProperties } from '@/util/date/calendar.util';
import { setLocalVar } from '@/util/localstorage-helper';
import { triggerMerchant } from '@/constants/names';

export default {
    name: 'GroupsView',
    components: {
        // GroupsSelect,
        TournamentsSelect,
        PageTitle,
        BTable,
        BCol,
        BRow,
        BPagination
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    mixins: [CRUDPageMixin, pasteImageFileAgent],
    data() {
        return {
            items: [],
            tournament: {
                id: '16',
                name: 'Супер тур'
            },
            group_id: '',
            merchantOptions: null,
            productOptions: null,
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            requestPending: false,
            isFetching: false,
            groupsOptions: [],
            groupsViewItems: [] /*
            [
                {
                    items:[],
                    group:{
                        name:string,
                        id:number
                    },
                    busy:boolean,
                    downloading:boolean,
                    pagination:{
                        current:number,
                        totalItem:number,
                        perPage:number
                    }
                }
            ] */
        };
    },

    computed: {
        fields() {
            return [
                { key: 'id', label: '#' },
                { key: 'group', label: this.$t('group') },
                { key: 'position', label: this.$t('titles.position') },
                {
                    key: 'name',
                    label: this.$t('titles.title'),
                    formatter: (item) => `${item?.user?.first_name}  ${item?.user?.last_name}`
                },
                { key: 'total_scores', label: this.$t('titles.score') },
                { key: 'phone', label: this.$t('titles.phone_number') },
                { key: 'images', label: this.$t('titles.image') },
                { key: 'gift', label: this.$t('titles.gifts') },
                { key: 'spend_time', label: this.$t('tournament.task_completing_time') },
                { key: 'actions', label: '' }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }

        // itemsSortByGroup() {
        //     const cItems = [...this.groupsViewItems];
        //     return cItems.sort((cPrev, cNext) => cPrev.group.name - cNext.group.name);
        // }
    },

    created() {
        // this.getCurrentMonth();
        this.initialize();
    },

    methods: {
        // getCurrentMonth() {
        //     const date = new Date();
        //     const currentMonthName = date.toLocaleString('default', { month: 'long' });
        //     console.log(currentMonthName);
        // },
        setLocalVar,
        checkLang,
        async initialize() {
            this.isFetching = true;
            try {
                const pMap = [];
                this.groupsViewItems = [];

                await this.getGroups();

                for (let i = 0; i < this.groupsOptions.length; i++) {
                    pMap.push(
                        this.getGroupUserById({
                            indexOfGroup: i,
                            groupId: this.groupsOptions[i].id
                        })
                    );
                }

                const vs = await Promise.allSettled(pMap);

                vs.forEach((v) => {
                    if (v.status === 'fulfilled') {
                        this.groupsViewItems.push(v.value);
                    }
                });

                this.groupsViewItems.sort(
                    (gItemPrev, gItemNext) =>
                        gItemPrev.group.name.charCodeAt(0) - gItemNext.group.name.charCodeAt(0)
                );
            } catch (e) {
                this.$toast?.error(e.response?.data?.message || e.message || this.$t('error.smth'));
            } finally {
                this.isFetching = false;
            }
        },

        async getGroupUserById({ groupId, page = 1, limit = 20 }) {
            const rsp = await api.tournament.fetchGroupUsers({
                page,
                limit,
                group_id: groupId
            });

            if (rsp.data.pagination.current > rsp.data.pagination.totalItem) {
                rsp.data.pagination.current = 1;
            }

            return {
                busy: false,
                downloading: false,
                items: rsp.data.result,
                pagination: rsp.data.pagination,
                group: this.groupsOptions.find((gOpt) => gOpt.id === groupId)
            };
        },
        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },
        selectTournament() {
            this.initialize();
        },
        async getGroups() {
            try {
                let query = { tournament_id: parseInt(this.tournament.id), page: 1, limit: 100 };
                const response = await api.tournament.fetchAllGroups(query);
                this.groupsOptions = response.data.result.map((r) => ({
                    id: r.id,
                    name: checkLocales(r.name)
                }));
            } catch (e) {
                this.$toast?.error(e.response?.data?.message || e.message || this.$t('error.smth'));
            }
        },
        async changeGroupItemPage({ groupId, page }) {
            const gIdx = this.groupsViewItems.findIndex((gVItem) => gVItem.group.id === groupId);
            if (gIdx !== -1) {
                try {
                    this.groupsViewItems[gIdx].busy = true;
                    const rsp = await this.getGroupUserById({ indexOfGroup: gIdx, groupId, page });
                    this.groupsViewItems.splice(gIdx, 1, rsp);
                } catch (e) {
                    this.$toast?.error(
                        e.response?.data?.message || e.message || this.$t('error.smth')
                    );
                } finally {
                    this.groupsViewItems[gIdx].busy = false;
                }
            }
        },
        changeMerchant() {
            triggerMerchant(1);
            setLocalVar('merchant', 1);
        },
        async downloadTop100({ index, group }) {
            try {
                this.groupsViewItems[index].downloading = true;

                const { items } = await this.getGroupUserById({
                    limit: 100,
                    groupId: group.id
                });

                const rows = [['Position', 'Group', 'Username', 'TOTAL_SCORE', 'PHONE', 'PRIZE']];

                for (const item of items) {
                    let name = '';
                    if (item.user.first_name) {
                        name += item.user?.first_name;
                    }

                    if (item.user.last_name) {
                        name += ' ' + item.user?.last_name;
                    }

                    rows.push([
                        item.position,
                        group.name,
                        name.trim(),
                        item.total_scores,
                        item.user.phone,
                        item.gift?.product?.name?.en
                    ]);
                }

                const ws = utils.json_to_sheet(rows);
                const wb = utils.book_new();
                utils.book_append_sheet(wb, ws, 'Data');
                const { dayOfMonth, month, year } = dateProperties(new Date());
                writeFileXLSX(
                    wb,
                    `${group.name}_GROUP_TOP_100_${dayOfMonth}_${addZero(month + 1)}_${year}.xlsx`
                );
            } catch (e) {
                this.$toast?.error(e.response?.data?.message || e.message || this.$t('error.smth'));
            } finally {
                this.groupsViewItems[index].downloading = false;
            }
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                <span style="text-transform: lowercase">
                    <span style="text-transform: capitalize">
                        {{ $t('navigation.tournament') }}
                    </span>
                    <span>
                        {{ $t('tournament.groups') }}
                    </span>
                </span>
            </template>
        </page-title>

        <div class="d-flex w-100 mb-2" style="gap: 2rem">
            <tournaments-select
                v-model="tournament"
                style="width: 200px"
                @input="selectTournament"
            />
            <!--            <groups-select-->
            <!--                :tournament-id="tournament.id"-->
            <!--                class="w-25"-->
            <!--                v-model="group_id"-->
            <!--                @input="groupSelected"-->
            <!--            />-->
        </div>

        <!--  TABLE  -->
        <div class="">
            <div v-for="(gTable, index) in groupsViewItems" :key="index" class="mt-4">
                <div class="d-flex align-items-end justify-content-between mb-2">
                    <h3>{{ gTable.group.name }}</h3>
                    <b-button
                        class="d-flex align-items-center"
                        variant="primary"
                        @click="downloadTop100({ index, group: gTable.group })"
                    >
                        <span>{{ $t('download_top_100') }} ({{ gTable.group.name }})</span>
                        <b-spinner
                            v-if="gTable.downloading"
                            class="ml-1"
                            label="Spinning"
                            small
                        ></b-spinner>
                    </b-button>
                </div>
                <b-table
                    :busy="gTable.busy"
                    :fields="fields"
                    :items="gTable.items"
                    :responsive="true"
                    borderless
                    class="news__table"
                    outlined
                    striped
                >
                    <template #cell(id)="{ item }">
                        {{ item.user.id }}
                    </template>

                    <template #cell(group)="">
                        {{ gTable.group.name }}
                    </template>

                    <template #cell(name)="{ item }">
                        <div style="min-width: 200px">
                            {{ item.user.first_name }} {{ item.user.last_name }}
                        </div>
                    </template>

                    <template #cell(position)="{ item }">
                        {{ item.position }}
                    </template>

                    <template #cell(score)="{ item }">
                        {{ item.total_scores }}
                    </template>

                    <template #cell(phone)="{ item }">
                        {{ item.user.phone }}
                    </template>

                    <template #cell(gift)="{ item }">
                        <span v-if="item.gift && item.gift.product">
                            {{ checkLang(item.gift.product.name) }}
                        </span>
                        <span v-else> </span>
                    </template>

                    <template #cell(images)="{ item }">
                        <div class="table-img">
                            <img
                                v-if="
                                    item.gift &&
                                    item.gift.product &&
                                    item.gift.product.images.length > 0
                                "
                                :src="item.gift.product.images[0].path"
                            />
                            <img v-else src="../../../assets/images/icons/no-photo.png" />
                        </div>
                    </template>

                    <template #cell(actions)="{ item }">
                        <div class="d-flex justify-content-end">
                            <b-button size="sm" variant="primary" @click="changeMerchant">
                                <router-link
                                    :to="{
                                        name: 'subscriber-task-progress',
                                        params: { id: item.user.id }
                                    }"
                                >
                                    <feather-icon color="#fff" icon="TrendingUpIcon" />
                                </router-link>
                            </b-button>
                        </div>
                    </template>
                </b-table>

                <!--  PAGINATION  -->
                <b-row>
                    <b-col class="offset-3" cols="9">
                        <b-pagination
                            v-model="gTable.pagination.current"
                            :per-page="gTable.pagination.perPage"
                            :total-rows="gTable.pagination.totalItem"
                            class="float-right mt-2 mb-2 mb-0"
                            first-number
                            last-number
                            @change="
                                changeGroupItemPage({
                                    page: $event,
                                    indexOfGroup: index,
                                    groupId: gTable.group.id
                                })
                            "
                        />
                    </b-col>
                </b-row>
            </div>
            <div v-if="isFetching" class="d-flex justify-content-center mt-4">
                <b-spinner label="Spinning" variant="primary"></b-spinner>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.table-img {
    width: 60px;
    height: 60px;
    background-color: #999999;
    border-radius: 0.25rem;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
    }
}
</style>
